
import { Vue, prop, Options } from "vue-class-component";
import Checkbox from "primevue/checkbox";
import PreviewImage from "@/app/ui/views/shipment/tracking-stt-cargo/tracking-stt-tab/component/preview-image-pod.vue";
import formatDateTrackingHistory from "@/app/infrastructures/misc/common-library/FormatDateTrackingHistory";
import formatDateBasedTimezoneValue from "@/app/infrastructures/misc/common-library/FormatDateBasedTimezoneValue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { SubmitDexAssessmentRequest } from "@/data/payload/api/DexAssessmentRequest";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import {
  DexAssessmentData,
  DexAssessmentList,
  DexAssessmentSummary,
  RequestDexAssessmentL1,
  RequestDexAssessmentSummary
} from "@/domain/entities/DexAssessment";
import UploadImageFileName from "@/app/ui/components/upload-image-file-name/index.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";

class Props {
  data = prop<DexAssessmentData>({
    default: new DexAssessmentData()
  });
  roleType = prop<string>({
    default: ""
  });
}
@Options({
  components: { Checkbox, PreviewImage, UploadImageFileName }
})
export default class CardSection extends Vue.with(Props) {
  openLink(location: string) {
    window.open(`https://www.google.com/maps/search/?api=1&query=${location}`);
  }
  images = [];
  statusDisputeAgenDexL2 = "";

  get isAgentDexL1() {
    return this.roleType === "Agent DEX L1";
  }
  get isAgentDexL2() {
    return this.roleType === "Agent DEX L2";
  }
  get isConsole() {
    return this.roleType.includes("console");
  }

  get isDisputeConsole() {
    return this.isAgentDexL2 && this.data.daFinishedPartnerDate.length;
  }
  get isShowDispute() {
    return this.data.daStatusDisputePartner === "";
  }
  get isStatusRejected() {
    return this.data.daStatusDisputePartner === "rejected";
  }
  get profile() {
    return AccountController.accountData;
  }
  get isShowDisputeL2Assigne() {
    return this.data.daAssignedL2DisputeAccountId === this.profile.account_id;
  }

  mounted() {
    this.daReasonDexAssessment = this.data.daReasonDexAssessment;
    this.daSourceDexAssessment = this.data.daSourceDexAssessment;
  }
  approveDex() {
    this.statusDisputeAgenDexL2 = "approved";
    this.doneDEX();
  }
  rejectedDex() {
    this.statusDisputeAgenDexL2 = "rejected";
    this.doneDEX();
  }
  get isDisableDoneDexL2() {
    return !this.data.daRemarksL2.length;
  }
  toggleCheckbox(value: boolean, type: string) {
    if (type === "photo") {
      this.data.daPhotoProofAssessment = !value;
    }
    if (type === "reason") {
      this.data.daReasonDexAssessment = !value;
    }
    if (type === "location") {
      this.data.daLocationDexAssessment = !value;
    }
    if (type === "source" && !this.uneditableSourceDex) {
      this.data.daSourceDexAssessment = !value;
    }
  }

  sourcePayload(): SubmitDexAssessmentRequest {
    if (this.isAgentDexL1) {
      return new SubmitDexAssessmentRequest({
        daPhotoProofAssessment: !!this.data.daPhotoProofAssessment, //used
        daReasonDexAssessment: !!this.data.daReasonDexAssessment, //used
        daLocationDexAssessment: !!this.data.daLocationDexAssessment, //used
        daSourceDexAssessment: !!this.data.daSourceDexAssessment, //used
        daRemarksL2Dispute: this.data.daRemarksL2Dispute,
        daStatusDisputePartner: this.data.daStatusDisputePartner,
        daRemarksL2: this.data.daRemarksL2,
        daNeedToRts: this.data.daNeedToRTS
      });
    }
    if (this.isDisputeConsole) {
      return new SubmitDexAssessmentRequest({
        daPhotoProofAssessment: this.data.daPhotoProofAssessment,
        daReasonDexAssessment: this.data.daReasonDexAssessment,
        daLocationDexAssessment: this.data.daLocationDexAssessment,
        daSourceDexAssessment: this.data.daSourceDexAssessment,
        daRemarksL2Dispute: this.data.daRemarksL2Dispute, //used
        daStatusDisputePartner: this.statusDisputeAgenDexL2, //used
        daRemarksL2: this.data.daRemarksL2,
        daNeedToRts: this.data.daNeedToRTS
      });
    }
    if (this.isConsole && !this.isShowTimeOverDispute) {
      return new SubmitDexAssessmentRequest({
        daPhotoProofAssessment: this.data.daPhotoProofAssessment,
        daReasonDexAssessment: this.data.daReasonDexAssessment,
        daLocationDexAssessment: this.data.daLocationDexAssessment,
        daSourceDexAssessment: this.data.daSourceDexAssessment,
        daRemarksL2Dispute: this.data.daRemarksL2Dispute,
        daStatusDisputePartner:
          this.statusDisputeAgenDexL2 === "rejected" ? "requested" : "accepted", //used
        daProofPhotoDisputePartner: this.data.daProofPhotoDisputePartner, //used
        daRemarksPartner: this.data.daRemarksPartner, //used
        daRemarksL2: this.data.daRemarksL2,
        daNeedToRts: this.data.daNeedToRTS
      });
    }
    return new SubmitDexAssessmentRequest({
      //isAgentDEXL2
      daPhotoProofAssessment: this.data.daPhotoProofAssessment,
      daReasonDexAssessment: this.data.daReasonDexAssessment,
      daLocationDexAssessment: this.data.daLocationDexAssessment,
      daSourceDexAssessment: this.data.daSourceDexAssessment,
      daRemarksL2Dispute: this.data.daRemarksL2Dispute,
      daStatusDisputePartner: this.data.daStatusDisputePartner,
      daRemarksL2: this.data.daRemarksL2, //used
      daNeedToRts: this.prosesName === "iya" //used
    });
  }

  isValid = false;
  daReasonDexAssessment = false;
  daSourceDexAssessment = false;
  async doneDEX() {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    const payload = this.sourcePayload();
    this.isValid = !this.convertValidOrInvalid(payload);
    try {
      this.customStyles();
      await DexAssessmentController.submitTicketDexAssesmentL1({
        payload,
        id: this.data.daId
      });
      await DexAssessmentController.setNotificationTicket(true);
      this.onSuccessDoneDEX();
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Submit Dex Assessment Gagal !", () =>
          this.doneDEX()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
  async onSuccessDoneDEX() {
    DexAssessmentController.setSearch("");
    DexAssessmentController.setFilterReasonCode("");
    DexAssessmentController.setFirstPage();
    DexAssessmentController.setIsFirstLanding(true);
    DexAssessmentController.setStatusDisputePartner("");
    if (DexAssessmentController.isConsole) {
      DexAssessmentController.setStartDate(
        DexAssessmentController.filter.startDate
      );
      DexAssessmentController.setEndDate(
        DexAssessmentController.filter.endDate
      );
    }

    // get list summary
    const summaryResponse: DexAssessmentSummary = await DexAssessmentController.getDexAssessmentSummaryOnly(
      new RequestDexAssessmentSummary({
        startDate: DexAssessmentController.filter.startDate
          ? new Date(
              DexAssessmentController.filter.startDate
            ).toLocaleDateString("fr-CA")
          : "",
        endDate: DexAssessmentController.filter.endDate
          ? new Date(DexAssessmentController.filter.endDate).toLocaleDateString(
              "fr-CA"
            )
          : ""
      })
    );
    DexAssessmentController.setDexAssessmentSummary(summaryResponse);

    // get list dex assesment
    DexAssessmentController.setInfiniteScroll(false);
    const listResponse: DexAssessmentList = await DexAssessmentController.getDexAssessmentListOnly(
      new RequestDexAssessmentL1({
        page: DexAssessmentController.dexAssessmentList.pagination.page,
        limit: DexAssessmentController.dexAssessmentList.pagination.limit,
        q: "",
        ticketTypes: "",
        startAssignedPartnerDate: DexAssessmentController.filter.startDate
          ? new Date(
              DexAssessmentController.filter.startDate
            ).toLocaleDateString("fr-CA")
          : "",
        endAssignedPartnerDate: DexAssessmentController.filter.endDate
          ? new Date(DexAssessmentController.filter.endDate).toLocaleDateString(
              "fr-CA"
            )
          : "",
        statusFollowup: "",
        statusDisputePartner: "",
        reasonCode: ""
      })
    );
    DexAssessmentController.setDexAssessmentList(listResponse.data);

    DexAssessmentController.setIsFirstLanding(false);
  }

  customStyles() {
    if (this.isAgentDexL2) {
      if (this.data.daFinishedPartnerDate.length) {
        DexAssessmentController.setCustomStyleNotification({
          background: "bg-green-lp-500",
          text: "font-semibold text-green-lp-400",
          icon: "snackbar-success",
          message: `Tiket ID ${
            this.data.daTicketNo
          } telah berhasil diselesaikan dengan status Sanggahan ${
            this.statusDisputeAgenDexL2 === "approved"
              ? "Disetujui"
              : "Tidak Disetujui"
          }`,
          width: "w-300px",
          border: "border-green-lp-400"
        });
        return;
      }
      DexAssessmentController.setCustomStyleNotification({
        background: "bg-green-lp-500",
        text: "font-semibold text-green-lp-400",
        icon: "snackbar-success",
        message: `Tiket ID ${this.data.daTicketNo} telah berhasil diselesaikan`,
        width: "w-300px",
        border: "border-green-lp-400"
      });
      return;
    }
    if (!this.isShowTimeOverDispute && this.isConsole) {
      DexAssessmentController.setCustomStyleNotification({
        background: "bg-green-lp-500",
        text: "font-semibold text-green-lp-400",
        icon: "snackbar-success",
        message: `Tiket ID ${this.data.daTicketNo} telah berhasil ${
          this.statusDisputeAgenDexL2 === "approved" ? "diterima" : "disanggah"
        }`,
        width: "w-300px",
        border: "border-green-lp-400"
      });
      return;
    }
    DexAssessmentController.setCustomStyleNotification({
      background: "bg-green-lp-500",
      text: "font-semibold text-green-lp-400",
      icon: "snackbar-success",
      message: `Tiket ID ${
        this.data.daTicketNo
      } telah berhasil diselesaikan dengan status ${
        this.isValid ? "sesuai" : "tidak sesuai"
      }`,
      width: "w-300px",
      border: "border-green-lp-400"
    });
  }

  convertValidOrInvalid(data: any) {
    let result: any = false;
    Object.entries(data.payload).forEach(([, value]) => {
      if (value) {
        result = value;
      }
    });
    return result;
  }
  toDetail(id: any) {
    this.$router.push(
      `/dex-assessment${
        this.isAgentDexL1 ? "-l1" : !this.isAgentDexL2 ? "" : "-l2"
      }/detail/${id}`
    );
  }
  showPreviewImage = false;

  actionModalPreview(boolean: boolean, type: string) {
    this.showPreviewImage = boolean;
    if (type === "console") {
      this.images = this.data.daProofPhotoDisputePartner;
    }
    if (type === "dex") {
      this.images = this.data.daDeliveryProofPhoto;
    }
  }

  deliveryDate(date: string) {
    return formatDateBasedTimezoneValue(date);
  }

  selectProses = [
    { name: "Pilih", value: "" },
    { name: "Iya", value: "iya" }
  ];
  prosesSelect = false;
  prosesName = "";
  onOpenprosesSelect() {
    this.prosesSelect = true;
  }

  onCloseprosesSelect() {
    this.prosesSelect = false;
  }
  onSelectproses(name: string, value: string) {
    if (this.prosesName) {
      this.prosesName = "";
    } else {
      this.prosesName = value;
    }
    this.onCloseprosesSelect();
  }

  get timeLeft() {
    const newDate = new Date(this.data.daTimeLeftPartnerDate);
    return formatDateTrackingHistory(newDate, "GMT+7:00");
  }

  goToWhatsapp(phoneNumber: string) {
    const phoneFormat = phoneNumber
      ?.replace(/\+/g, "")
      .slice(1, phoneNumber.length);
    window.open(
      `https://api.whatsapp.com/send?phone=62${phoneFormat}`,
      "_blank"
    );
  }

  get isShowTimeOverDispute() {
    const currentTime = new Date().getTime();
    const timeLeft = new Date(this.data.daTimeLeftPartnerDate).getTime();
    return currentTime > timeLeft;
  }

  get uneditableSourceDex(): boolean {
    return (
      !!this.data.daSourceDexAssessmenFromSystemt &&
      !!this.data.daSourceDex.match(/genesis/i) &&
      this.isAgentDexL1
    );
  }

  get editable() {
    const isEdit = !this.isConsole && !this.data.daFinishedL2Date;
    if (this.isAgentDexL2) {
      return isEdit && this.data.daDeliveryReasonCategory === "customer";
    }
    return isEdit;
  }
}
