
import { Options, Vue } from "vue-class-component";
import Summary from "./component/summary-section.vue";
import SummaryConsole from "./component/summary-section-console.vue";
import CardSection from "./component/card-section.vue";
import FilterSection from "./component/filter-section.vue";
import { DexAssessmentController } from "../../controllers/DexAssessmentController";
import { RequestDexAssessmentL1 } from "@/domain/entities/DexAssessment";
import { AccountController } from "../../controllers/AccountController";
import { MainAppController } from "../../controllers/MainAppController";

@Options({
  components: {
    Summary,
    SummaryConsole,
    CardSection,
    FilterSection
  }
})
export default class DexAssessment extends Vue {
  mounted() {
    if (!this.isConsole) {
      this.getAssessmentList();
    }
  }

  unmounted() {
    DexAssessmentController.setStatusFollowup("");
    DexAssessmentController.setSearch("");
    DexAssessmentController.setFilterReasonCode("");
    DexAssessmentController.setStatusDisputePartner("");
    DexAssessmentController.setInfiniteScroll(false);
    DexAssessmentController.setStartDate("");
    DexAssessmentController.setEndDate("");
  }
  // notification
  get notification() {
    return DexAssessmentController.notificationTicket;
  }

  get customStyles() {
    return DexAssessmentController.customStylesNotification;
  }

  hideNotification() {
    DexAssessmentController.setNotificationTicket(false);
  }

  get roleType() {
    return AccountController.accountData.role_type;
  }
  get currentRoute() {
    return this.$route.name;
  }
  get isConsole() {
    return this.roleType.includes("console");
  }

  get dataSummary() {
    return DexAssessmentController.dexAssessmentSummary;
  }
  get dataListDex() {
    return DexAssessmentController.dexAssessmentList.data;
  }

  getAssessmentList() {
    DexAssessmentController.initDataTable();
  }
  get isMoreData() {
    return DexAssessmentController.isMoreData;
  }
  get isFirstLanding() {
    return DexAssessmentController.isFirstLanding;
  }
  handleScroll() {
    // Load more data when reaching the bottom
    if (this.isBottomReached() && this.isMoreData) {
      setTimeout(() => {
        this.getNewData();
      }, 1000);
    }
  }
  isBottomReached() {
    const element: any = document.querySelector("#content");

    return (
      Math.round(element?.scrollHeight - element?.scrollTop) ===
      element?.clientHeight
    );
  }
  getNewData() {
    DexAssessmentController.dexAssessmentList.pagination.page += 1;
    DexAssessmentController.setInfiniteScroll(true);
    DexAssessmentController.getDexAssessmentList(
      new RequestDexAssessmentL1({
        page: DexAssessmentController.dexAssessmentList.pagination.page,
        limit: DexAssessmentController.dexAssessmentList.pagination.limit,
        q: DexAssessmentController.search,
        ticketTypes: DexAssessmentController.ticketTypes,
        startAssignedPartnerDate: DexAssessmentController.filter.startDate
          ? new Date(
              DexAssessmentController.filter.startDate
            ).toLocaleDateString("fr-CA")
          : "",
        endAssignedPartnerDate: DexAssessmentController.filter.endDate
          ? new Date(DexAssessmentController.filter.endDate).toLocaleDateString(
              "fr-CA"
            )
          : "",
        statusFollowup: DexAssessmentController.filter.statusFollowup,
        statusDisputePartner:
          DexAssessmentController.filter.statusDisputePartner,
        reasonCode: DexAssessmentController.filter.reasonCode
      })
    );
  }
  get isLoading() {
    return DexAssessmentController.isLoading || MainAppController.loadingModal;
  }
  get isInfiniteScroll() {
    return DexAssessmentController.isInfiniteScroll;
  }
}
