
import { Options, Vue, prop } from "vue-class-component";

class Props {
  modelValue = prop<any>({
    required: true
  });
  title = prop<string>({
    default: "Klik Untuk Upload",
    type: String
  });
  isClear = prop<boolean>({
    default: true,
    type: Boolean
  })
  disabled = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
   emits: ["update:modelValue", "change"],
   watch: {
    "props.isClear"() {
      if (this.props.isClear) this.setDefault();
    }
  }
})
export default class UploadImageFileName extends Vue.with(Props) {
  isDataValidate = false;
  isDragFile = false;
  fileOriginal = "";
  isType = "";

  $refs!: {
    file: HTMLFormElement;
  };
  imagePreview: any = "";
  changeBannerImage: any = "";
  isImageChange = false;
  fileName = "";

  onUploadImage(file: any) {
    const files = file.target.files[0];
    if (files && files.size < 5000001) {
      this.$emit('update:modelValue', files);
      this.$emit('change', file);
      this.imagePreview = URL.createObjectURL(files);
      this.fileName = file.target.files[0].name;
      this.isImageChange = true;
    } else {
      this.isImageChange = false;
      this.$emit("update:modelValue", "");
    }
  }

  setDefault() {
    this.$emit("update:modelValue", "");
    this.$refs.file.value = "";
    this.fileName = "";
    this.imagePreview = "";
    this.isDataValidate = false;
    this.isImageChange = false;
    this.isDragFile = false;
  }

}
