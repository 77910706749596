
import { Vue, prop } from "vue-class-component";
class Props {
  data = prop<any>({
    default: []
  });
  roleType = prop<string>({
    default: ""
  });
}
export default class Summary extends Vue.with(Props) {
  get isAgentDexL1() {
    return this.roleType === "Agent DEX L1";
  }
  get isAgentDexL2() {
    return this.roleType === "Agent DEX L2";
  }
}
