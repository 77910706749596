
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import { ReasonListDex } from "@/data/payload/api/PodDexRequest";
import { RequestDexAssessmentL1, RequestDexAssessmentSummary } from "@/domain/entities/DexAssessment";
import { ReasonData } from "@/domain/entities/PodDex";
import { Vue, prop, Options } from "vue-class-component";
import Checkbox from "primevue/checkbox";

class Props {
  data = prop<any>({
    default: []
  });
  roleType = prop<string>({
    default: ""
  });
}
@Options({
  components: { Checkbox }
})
export default class FilterSection extends Vue.with(Props) {
  mounted() {
    this.getReasonList();
  }
  isDispute = false;
  isL1 = false;
  isDEL = false;
  isRTS = false;

  get isAgentDexL1() {
    return this.roleType === "Agent DEX L1";
  }
  get isAgentDexL2() {
    return this.roleType === "Agent DEX L2";
  }

  get isConsole() {
    return this.roleType.includes("console");
  }

  get isLoadingReasonDex() {
    return PodDexController.isLoadingDex;
  }
  getReasonList() {
    PodDexController.getReasonList(
      new ReasonListDex({
        statusCode: "DEX",
        reasonStatus: "active"
      })
    );
  }
  get reasonList() {
    const res: any = [];
    PodDexController.reasonList.forEach((key: ReasonData) => {
      res.push({
        name: `${key.reasonCode} - ${key.reasonDescription}`,
        value: key.reasonCode
      });
    });
    return res;
  }

  reasonValue = [];
  async onCheckRTS() {
    let statusFollowup = "";
    if (this.isRTS) {
      statusFollowup = "rts";
      this.isDEL = false;
    }
    DexAssessmentController.setFirstPage();
    DexAssessmentController.setIsFirstLanding(true);
    DexAssessmentController.setInfiniteScroll(false);
    await DexAssessmentController.setStatusFollowup(statusFollowup);
    await DexAssessmentController.fetchData();
  }

  async onCheckbox() {
    const result: any = [];
    let statusFollowup = "";
    if (this.isDispute) {
      result.push("dispute");
    }
    if (this.isL1) {
      result.push("l2");
    }
    if (this.isDEL) {
      statusFollowup = "del";
      this.isRTS = false;
    }
    DexAssessmentController.setFirstPage();
    DexAssessmentController.setIsFirstLanding(true);
    DexAssessmentController.setInfiniteScroll(false);
    await DexAssessmentController.setTicketTypes(result);
    await DexAssessmentController.setStatusFollowup(statusFollowup);
    await DexAssessmentController.fetchData();
  }
  onSelectReasonType(value: any) {
    const mappingValue = value.map((key: any) => key.value);
    DexAssessmentController.setIsFirstLanding(true);
    DexAssessmentController.setFirstPage();
    DexAssessmentController.setInfiniteScroll(false);
    DexAssessmentController.setFilterReasonCode(mappingValue.toString());
    DexAssessmentController.fetchData();
  }
  async takeTicketDex() {
    DexAssessmentController.setIsFirstLanding(true);
    await DexAssessmentController.assignDexAssessment();
    await DexAssessmentController.getDexAssessmentSummary(
      new RequestDexAssessmentSummary({
        startDate: DexAssessmentController.filter.startDate
          ? new Date(DexAssessmentController.filter.startDate).toLocaleDateString("fr-CA")
          : "",
        endDate: DexAssessmentController.filter.endDate
          ? new Date(DexAssessmentController.filter.endDate).toLocaleDateString("fr-CA")
          : ""
    }));
    DexAssessmentController.setFirstPage();
    DexAssessmentController.setInfiniteScroll(false);
    await DexAssessmentController.getDexAssessmentList(
      new RequestDexAssessmentL1({
        page: 1,
        limit: DexAssessmentController.dexAssessmentList.pagination.limit,
        reasonCode: DexAssessmentController.filter.reasonCode
      })
    );
    DexAssessmentController.setIsFirstLanding(false);
  }
  //Filter Search
  search = "";
  onSearch(value: string) {
    DexAssessmentController.setInfiniteScroll(false);
    DexAssessmentController.searchAction(value);
  }
  get searchValue() {
    return DexAssessmentController.search;
  }
  clearSearch() {
    DexAssessmentController.clear();
    DexAssessmentController.setInfiniteScroll(false);
    this.search = "";
  }
  onBlur() {
    PodDexController.setLoadingDex(true);
  }
  // filter date
  minDate: any = null;
  maxDate: any = null;
  isStartDate = true;
  setDay(day: any) {
    if (this.isStartDate) {
      DexAssessmentController.setStartDate(null);
      DexAssessmentController.setEndDate(null);
      this.minDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() - 13)
      );
      this.maxDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() + 13)
      );
      this.isStartDate = false;
    } else {
      this.endDate && (this.isStartDate = true);
    }
  }

  get startDate() {
    return DexAssessmentController.filter.startDate;
  }

  get endDate() {
    return DexAssessmentController.filter.endDate;
  }

  setDateRange(value: Array<any>) {
    DexAssessmentController.setStartDate(value[0]);
    DexAssessmentController.setEndDate(value[1]);
    DexAssessmentController.setFirstPage();
    DexAssessmentController.setIsFirstLanding(true);
    DexAssessmentController.setInfiniteScroll(false);
    DexAssessmentController.getDexAssessmentSummary(
      new RequestDexAssessmentSummary({
        startDate: DexAssessmentController.filter.startDate
          ? new Date(DexAssessmentController.filter.startDate).toLocaleDateString("fr-CA")
          : "",
        endDate: DexAssessmentController.filter.endDate
          ? new Date(DexAssessmentController.filter.endDate).toLocaleDateString("fr-CA")
          : ""
    }));
    value[0] && value[1] && DexAssessmentController.fetchData();
  }
}
