/* eslint-disable @typescript-eslint/no-unused-vars */
import ConvertObjectCamelToSnakeCase from "@/app/infrastructures/misc/common-library/ConvertObjectCamelToSnakeCase";
import toFormDataUtils from "@/app/infrastructures/misc/common-library/ToFormDataUtils";
import { SubmitDexAssesmentL1 } from "@/domain/entities/DexAssessment";
import { DexAssesmentRequestInterface } from "../contracts/DexAssessmentRequest";

export class SubmitDexAssessmentRequest implements DexAssesmentRequestInterface {
    payload: SubmitDexAssesmentL1 = new SubmitDexAssesmentL1();

    constructor(fields?: Partial<SubmitDexAssesmentL1>) {
        Object.assign(this.payload, fields);
    }
    toFormData(): FormData {
        return toFormDataUtils(ConvertObjectCamelToSnakeCase(this.payload));
    }
}
