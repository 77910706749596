
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { RequestDexAssessmentL1 } from "@/domain/entities/DexAssessment";
import { Vue, prop } from "vue-class-component";
class Props {
  data = prop<any>({
    default: []
  });
  roleType = prop<string>({
    default: ""
  });
}
export default class SummaryConsole extends Vue.with(Props) {
  get isAgentDexL1() {
    return this.roleType === "Agent DEX L1";
  }
  get isAgentDexL2() {
    return this.roleType === "Agent DEX L2";
  }
  get statusDisputePartner() {
    return DexAssessmentController.filter.statusDisputePartner;
  }
  get isRequestedActive() {
    return this.statusDisputePartner === "requested";
  }
  get isApprovedActive() {
    return this.statusDisputePartner === "approved";
  }
  get isRejectedActive() {
    return this.statusDisputePartner === "rejected";
  }
  get isAcceptedActive() {
    return this.statusDisputePartner === "accepted";
  }
  onClickSummary(status: string) {
    if (status === this.statusDisputePartner) {
      DexAssessmentController.setStatusDisputePartner("");
    } else {
      DexAssessmentController.setStatusDisputePartner(status);
    }
    DexAssessmentController.setFirstPage();
    DexAssessmentController.setIsFirstLanding(true);
    DexAssessmentController.setInfiniteScroll(false);
    DexAssessmentController.getDexAssessmentList(
      new RequestDexAssessmentL1({
        page: 1,
        limit: DexAssessmentController.dexAssessmentList.pagination.limit,
        q: DexAssessmentController.search,
        ticketTypes: DexAssessmentController.ticketTypes,
        startAssignedPartnerDate: DexAssessmentController.filter.startDate ? new Date(DexAssessmentController.filter.startDate).toLocaleDateString("fr-CA") : "",
        endAssignedPartnerDate: DexAssessmentController.filter.endDate ? new Date(DexAssessmentController.filter.endDate).toLocaleDateString("fr-CA") : "",
        statusFollowup: DexAssessmentController.filter.statusFollowup,
        statusDisputePartner: DexAssessmentController.filter.statusDisputePartner,
        reasonCode: DexAssessmentController.filter.reasonCode
      })
    );
  }
}
